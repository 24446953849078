import { Divider, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react';
import { Editor, useValue } from 'tldraw';
import { WhiteboardTool, WhiteboardToolColour } from '@hoot/events/interfaces/whiteboard.state';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import PopoverMenu, { MenuItem } from '@hoot/ui/components/v2/core/PopoverMenu';
import ColourOption from '@hoot/ui/components/v2/icons/ColourOption';
import Copy from '@hoot/ui/components/v2/icons/Copy';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { useToolsContext } from '@hoot/ui/context/ToolsContext';

interface TeacherInLessonWhiteboardHeaderProps {
  editor: Editor | undefined;
  currentTool: WhiteboardTool;
  currentToolColour: WhiteboardToolColour;
  onCloseWhiteboard: () => void;
  onResetWhiteboard: () => void;
  onRequestToolChange: (tool: WhiteboardTool, colour: WhiteboardToolColour) => void;
  onRequestRecenter: () => void;
}

const TeacherInLessonWhiteboardHeader = (props: TeacherInLessonWhiteboardHeaderProps) => {
  const { editor, currentTool, currentToolColour, onCloseWhiteboard, onResetWhiteboard, onRequestToolChange, onRequestRecenter } = props;

  const isShapeSelected = useValue('isShapeSelected', () => (editor?.getSelectedShapeIds().length ?? 0) > 0, [editor]);
  const canUndo = useValue('canUndo', () => !!editor?.getCanUndo(), [editor]);
  const canRedo = useValue('canRedo', () => !!editor?.getCanRedo(), [editor]);

  const [toolsIconButtonElement, setToolsIconButtonElement] = useState<null | HTMLElement>(null);

  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const {
    isCelebrationsDialogVisible,
    onShowCelebrationsDialog,
    onHideCelebrationsDialog,
    isNotepadVisible,
    onShowNotepad,
    onHideNotepad,
    isTimerVisible,
    onShowTimer,
    onHideTimer,
  } = useToolsContext();

  const toolsMenuItems = (): MenuItem[] => {
    return [
      { id: 'timer', label: 'Timer', icon: <Icon name="clock" />, onClick: toggleShowTimer },
      {
        id: 'go-fullscreen',
        label: isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen',
        icon: <Icon name={isFullscreen ? 'exit_full_screen' : 'enter_full_screen'} />,
        onClick: onToggleFullscreen,
      },
      {
        id: 'notepad',
        label: 'Notepad',
        icon: <Icon name="pen" />,
        onClick: toggleShowNotepad,
      },
      {
        id: 'celebrations',
        label: 'Celebrations',
        icon: <Icon name="hoot_wheel" />,
        onClick: toggleCelebrationsDialog,
      },
    ];
  };

  const onToggleFullscreen = async () => {
    dismissToolsMenu();
    await toggleFullscreen();
  };

  const toggleShowTimer = () => {
    if (isTimerVisible) {
      onHideTimer();
    } else {
      onShowTimer();
    }
    dismissToolsMenu();
  };

  const toggleShowNotepad = () => {
    if (isNotepadVisible) {
      onHideNotepad();
    } else {
      onShowNotepad();
    }
    dismissToolsMenu();
  };

  const toggleCelebrationsDialog = () => {
    if (isCelebrationsDialogVisible) {
      onHideCelebrationsDialog();
    } else {
      onShowCelebrationsDialog();
    }
    dismissToolsMenu();
  };

  const handleUndoClick = () => editor?.undo();

  const handleRedoClick = () => editor?.redo();

  const handleCopyClick = () =>
    editor?.duplicateShapes(editor?.getSelectedShapeIds(), {
      x: 32,
      y: 32,
    });

  const showToolsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToolsIconButtonElement(event.currentTarget);
  };

  const dismissToolsMenu = () => {
    setToolsIconButtonElement(null);
  };

  return (
    <Card
      sx={{
        borderRadius: '8px',
      }}
      contentSx={{
        padding: 0,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" padding={2}>
        <HootTypography variant="titlelarge" isPII={false}>
          Whiteboard
        </HootTypography>
        <Stack direction="row" gap={1} alignItems="center">
          <Button variant="outlined" size="small" onClick={onResetWhiteboard}>
            Reset
          </Button>
          <Button variant="outlined" size="small" onClick={onRequestRecenter}>
            Re-center
          </Button>
          <Button variant="outlined" size="small" onClick={handleUndoClick} disabled={!canUndo}>
            Undo
          </Button>
          <Button variant="outlined" size="small" onClick={handleRedoClick} disabled={!canRedo}>
            Redo
          </Button>
          <Stack direction="row" alignItems="center" gap={1}>
            <Tooltip title="Show Tools Menu">
              <IconButton onClick={showToolsMenu}>
                <Icon name="divider_caliper" />
              </IconButton>
            </Tooltip>
            <PopoverMenu
              id="tools-menu"
              variant="dark"
              anchorEl={toolsIconButtonElement}
              open={!!toolsIconButtonElement}
              onClose={dismissToolsMenu}
              items={toolsMenuItems()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            />
            <Tooltip title="Return to Library">
              <span>
                <IconButton onClick={onCloseWhiteboard}>
                  <Icon name="close_filled_square" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>
      <Divider />
      {/* Sub-Menu */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} paddingX={2} paddingY={0.25}>
        <Box>
          {/* Select tool options */}
          {currentTool === WhiteboardTool.Select && (
            <Tooltip title="Duplicate selected shape(s)" enterDelay={500}>
              <span>
                <IconButton onClick={handleCopyClick} disabled={!isShapeSelected}>
                  <Copy />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {/* Colour options */}
          {currentTool === WhiteboardTool.Draw && (
            <>
              <ToggleButtonGroup
                value={currentToolColour}
                orientation="horizontal"
                exclusive
                onChange={(_e, value) => onRequestToolChange(currentTool, value)}
                sx={{
                  gap: 2,
                  '& > button.Mui-selected, & > button:hover': {
                    backgroundColor: '#DADADA',
                    borderRadius: '8px',
                  },
                  '& > button': {
                    border: 'unset',
                    padding: '10px',
                  },
                }}
              >
                <ToggleButton value={WhiteboardToolColour.Black} aria-label={WhiteboardToolColour.Black}>
                  <ColourOption fill={WhiteboardToolColour.Black} />
                </ToggleButton>
                <ToggleButton value={WhiteboardToolColour.Green} aria-label={WhiteboardToolColour.Green}>
                  <ColourOption fill={WhiteboardToolColour.Green} />
                </ToggleButton>
                <ToggleButton value={WhiteboardToolColour.Blue} aria-label={WhiteboardToolColour.Blue}>
                  <ColourOption fill={WhiteboardToolColour.Blue} />
                </ToggleButton>
                <ToggleButton value={WhiteboardToolColour.Orange} aria-label={WhiteboardToolColour.Orange}>
                  <ColourOption fill={WhiteboardToolColour.Orange} />
                </ToggleButton>
                <ToggleButton value={WhiteboardToolColour.Red} aria-label={WhiteboardToolColour.Red}>
                  <ColourOption fill={WhiteboardToolColour.Red} />
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
        </Box>
        <Stack direction="row" alignItems="center" gap={2}>
          <Tooltip title="Select and Drag" enterDelay={800}>
            <IconButton
              value={WhiteboardTool.Select}
              aria-label="select"
              variant={currentTool === WhiteboardTool.Select ? 'contained' : 'standard'}
              onClick={() => onRequestToolChange(WhiteboardTool.Select, WhiteboardToolColour.Black)}
            >
              <Icon name="drag" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Draw" enterDelay={800}>
            <IconButton
              value={WhiteboardTool.Draw}
              aria-label="draw"
              variant={currentTool === WhiteboardTool.Draw ? 'contained' : 'standard'}
              onClick={() => onRequestToolChange(WhiteboardTool.Draw, currentToolColour)}
            >
              <Icon name="pen" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Erase" enterDelay={800}>
            <IconButton
              value={WhiteboardTool.Eraser}
              aria-label="eraser"
              variant={currentTool === WhiteboardTool.Eraser ? 'contained' : 'standard'}
              onClick={() => onRequestToolChange(WhiteboardTool.Eraser, WhiteboardToolColour.Black)}
            >
              <Icon name="eraser" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Type" enterDelay={800}>
            <IconButton
              value={WhiteboardTool.Text}
              aria-label="text"
              variant={currentTool === WhiteboardTool.Text ? 'contained' : 'standard'}
              onClick={() => onRequestToolChange(WhiteboardTool.Text, WhiteboardToolColour.Black)}
            >
              <Icon name="type_letters" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Card>
  );
};

export default TeacherInLessonWhiteboardHeader;

import { Stack } from '@mui/system';
import { FloatingDialog } from '@hoot/ui/components/v2/core/FloatingDialog';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import {
  CancellationIcon,
  CompletedLessonIcon,
  FormativeAssessmentIcon,
  HootReadingAssessmentIcon,
  InstructionRequiredIcon,
  LessonPrimaryFocusIcon,
  LessonSecondaryFocusIcon,
  MasteredIcon,
  PassedIcon,
  PreviouslyMasteredOrPassedIcon,
  ProgressMonitoringAssessmentIcon,
  ReviewRequiredIcon,
  ScheduledLessonIcon,
  StudentAbsenceIcon,
} from '@hoot/ui/components/v2/icons/timeline-legend/index';
import { AssessmentOutcomes, LessonReviewFocuses, TimelineEvents } from './enums';

interface TimelineLegendDialogProps {
  open: boolean;
  onDismiss: () => void;
}

const timelineEventRecord: Record<TimelineEvents, JSX.Element> = {
  [TimelineEvents.HootReadingAssessment]: <HootReadingAssessmentIcon />,
  [TimelineEvents.ProgressMonitoringAssessment]: <ProgressMonitoringAssessmentIcon />,
  [TimelineEvents.CompletedLesson]: <CompletedLessonIcon />,
  [TimelineEvents.ScheduledLesson]: <ScheduledLessonIcon />,
  [TimelineEvents.StudentAbsence]: <StudentAbsenceIcon />,
  [TimelineEvents.Cancellation]: <CancellationIcon />,
};

const assessmentOutcomesRecord: Record<AssessmentOutcomes, JSX.Element> = {
  [AssessmentOutcomes.Mastered]: <MasteredIcon />,
  [AssessmentOutcomes.Passed]: <PassedIcon />,
  [AssessmentOutcomes.ReviewRequired]: <ReviewRequiredIcon />,
  [AssessmentOutcomes.InstructionRequired]: <InstructionRequiredIcon />,
};

const lessonReviewFocusesRecord: Record<LessonReviewFocuses, JSX.Element> = {
  [LessonReviewFocuses.PreviouslyPassedOrMastered]: <PreviouslyMasteredOrPassedIcon />,
  [LessonReviewFocuses.LessonPrimaryFocus]: <LessonPrimaryFocusIcon />,
  [LessonReviewFocuses.LessonSecondaryFocus]: <LessonSecondaryFocusIcon />,
  [LessonReviewFocuses.FormativeAssessment]: <FormativeAssessmentIcon />,
};

const timelineEvents = Object.entries(timelineEventRecord);
const assessmentOutcomes = Object.entries(assessmentOutcomesRecord);
const lessonReviewFocuses = Object.entries(lessonReviewFocusesRecord);

const TimelineLegendDialog = (props: TimelineLegendDialogProps) => {
  return (
    <>
      {props.open && (
        <FloatingDialog title="Legend" titleVariant="titlelarge" width="340px" onClose={props.onDismiss}>
          <Stack gap={2} mt={2}>
            <div>
              <HootTypography variant="titlesmall" mb={2} isPII={false}>
                Events
              </HootTypography>
              <Stack gap={2}>
                {timelineEvents.map((x) => (
                  <Stack key={`${x[0]}`} flexDirection="row" gap={2}>
                    {x[1]}
                    <HootTypography isPII={false}>{x[0]}</HootTypography>
                  </Stack>
                ))}
              </Stack>
            </div>

            <div>
              <HootTypography variant="titlesmall" mb={2} isPII={false}>
                Assessment Outcomes
              </HootTypography>
              <Stack gap={2}>
                {assessmentOutcomes.map((x) => (
                  <Stack key={`${x[0]}`} flexDirection="row" gap={2}>
                    {x[1]}
                    <HootTypography isPII={false}>{x[0]}</HootTypography>
                  </Stack>
                ))}
              </Stack>
            </div>

            <div>
              <HootTypography variant="titlesmall" mb={2} isPII={false}>
                Lesson Review Focuses
              </HootTypography>
              <Stack gap={2}>
                {lessonReviewFocuses.map((x) => (
                  <Stack key={`${x[0]}`} flexDirection="row" gap={2}>
                    {x[1]}
                    <HootTypography isPII={false}>{x[0]}</HootTypography>
                  </Stack>
                ))}
              </Stack>
            </div>
          </Stack>
        </FloatingDialog>
      )}
    </>
  );
};

export default TimelineLegendDialog;

import { Card } from '@mui/material';
import { Box } from '@mui/system';
import { Editor, TLStoreWithStatus, Tldraw } from 'tldraw';
import 'tldraw/tldraw.css';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface TeacherInLessonWhiteboardProps {
  store: TLStoreWithStatus;
  onEditorReady: (editor: Editor) => void;
}

/**
 * Tldraw whiteboard at its base (UI controls are stripped out), and wrapped in a card.
 */
const Whiteboard = (props: TeacherInLessonWhiteboardProps) => {
  const { store, onEditorReady } = props;

  return (
    <Card
      sx={{
        height: '100%',
        padding: 0,
        ...hootTokens.elevation.get(1),
      }}
    >
      <Box
        className="tldraw__editor"
        sx={{
          height: '100%',
          '& .tl-background': {
            backgroundColor: hootTokens.palette.white,
          },
        }}
      >
        <Tldraw
          hideUi
          autoFocus
          store={store}
          onMount={(e) => onEditorReady(e)}
          cameraOptions={{
            wheelBehavior: 'none',
            isLocked: true,
          }}
        />
      </Box>
    </Card>
  );
};

export default Whiteboard;

import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { PMAUnitSubmission, useSubmitPMAUnit } from '@hoot/hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { UnitQuestionAnswerPair } from './PMAPage';
import useTeacherPMAController from './useTeacherPMAController';

interface Props {
  allFormFieldsFilledOut: boolean;
  isThereAnotherUnit: boolean;
  isPreviousUnitDisabled: boolean;
  onPreviousUnitClick: () => void;
  unitQuestionAnswerPair: UnitQuestionAnswerPair[];
  setUnitQuestionAnswerPair: (val: UnitQuestionAnswerPair[]) => void;
  setIsUnitQuestionnaireDirty: (val: boolean) => void;
  isUnitQuestionnaireDirty: boolean;
  setLeaveDialog: (val: boolean) => void;
}

export function PMAFooter(props: Props) {
  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const pmaController = useTeacherPMAController();

  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, currentUnit);
  const disableSubmissionBtn = !props.allFormFieldsFilledOut || submitPMAUnitRequest.isLoading;
  const dispatch = useAppDispatch();

  const onNextUnitAndSubmissionClick = () => {
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: props.unitQuestionAnswerPair,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        props.setUnitQuestionAnswerPair([]);
        props.setIsUnitQuestionnaireDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  const onPreviousUnitClick = () => {
    if (props.isUnitQuestionnaireDirty) {
      props.setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ borderTop: '1px solid #D3D3D3', paddingTop: 3, marginTop: '16px' }}>
      <div style={{ display: 'flex', cursor: props.isPreviousUnitDisabled ? 'not-allowed' : 'default' }}>
        <Button
          variant="contained"
          startIcon={<Icon name="arrow_icon" color="primary.200" rotate="180deg" />}
          size="small"
          disabled={props.isPreviousUnitDisabled}
          onClick={onPreviousUnitClick}
        >
          Previous Unit
        </Button>
      </div>
      <div style={{ display: 'flex', cursor: !props.allFormFieldsFilledOut ? 'not-allowed' : 'default' }}>
        <Button
          variant="contained"
          startIcon={<Icon name="arrow_icon" color="primary.200" />}
          size="small"
          disabled={disableSubmissionBtn}
          onClick={onNextUnitAndSubmissionClick}
        >
          Submit and {props.isThereAnotherUnit ? 'Continue' : 'Close'}
        </Button>
      </div>
    </Stack>
  );
}

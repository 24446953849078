import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

export interface UpdateStudentGrade {
  grade: StudentGrade;
}

async function updateStudentGrade(userId: string, payload: UpdateStudentGrade): Promise<void> {
  return axios.put(`${config.apiUrl}/teacher/student-profile/v2/${userId}/update-grade`, payload);
}

export function useUpdateStudentGrade(
  userId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateStudentGrade>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((request) => updateStudentGrade(userId, request), options);
}

import { CancellationReason, ScheduledLessonStatus, lessonStatusesLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { TeacherShiftStatus, teacherShiftStatusLookup } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Drawer, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { TeacherAccountShiftResponse, useGetTeacherShifts } from '@hoot/hooks/api/availability/useGetTeacherShifts';
import { useGetTeacherAvailabilityExceptions } from '@hoot/hooks/api/availability/useTeacherAvailabilityException';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { chunkTimeSpan } from '@hoot/utils/date';
import useDeleteTeacherAvailabilityException from '../../../../../hooks/api/availability/useDeleteTeacherAvailabilityException';
import useGetLessons, { LessonSearchResponse } from '../../../../../hooks/api/lesson/useGetLessons';
import Card from '../../../../components/v2/core/Card';
import IconButton from '../../../../components/v2/core/IconButton';
import ReadOnlyTextField from '../../../../components/v2/core/ReadOnlyTextField';

/** Interfaces */

interface TimeOffEvent {
  id: string;
  startTime: DateTime;
  endTime: DateTime;
}

// NOTE: This isn't the same mapping as our standard cancellationReasonLookup
// in hoot-core - it is only used in this component and was spec'd in sc-11422 &
// https://docs.google.com/spreadsheets/d/1JfRhAjQcYlzy1QVfITvXTEMvr1aNkVdLf3t445Yy5v4/edit?gid=1044740678#gid=1044740678
export const cancellationReasonMessageLookup: Record<CancellationReason, string> = {
  HOOT_CLOSURE: 'Hoot Closure',
  TEACHER_NO_SHOW: 'Teacher No Show',
  TEACHER_UNAVAILABLE_BELOW_NOTICE: 'Teacher No Show',
  NO_ONE_JOINED: 'Teacher No Show',
  SUBSTITUTION: 'Other',
  OTHER: 'Other',
  TECH_DISRUPTION: 'Other',
  TEACHER_UNAVAILABLE_ABOVE_NOTICE: 'Schedule Change',
  STUDENT_UNAVAILABLE_ABOVE_NOTICE: 'Schedule Change',
  PARENT_CHANGE_REQUEST: 'Schedule Change',
  MEMBERSHIP_LAPSE: 'Schedule Change',
  NO_SUB_FOUND: 'Schedule Change',
  UNSUCCESSFUL_PAYMENT: 'Schedule Change',
  SCHEDULING_ERROR: 'Schedule Change',
  STUDENT_UNAVAILABLE_BELOW_NOTICE: 'Student Unavailable',
  STUDENT_ABSENCE: 'Student Unavailable',
  SITE_CLOSURE: 'Student Unavailable',
};

/** Components */
export function TeacherScheduleDrawer(props: {
  open?: boolean;
  onClose: () => void;
  selectedDate?: DateTime;
  showCancelledEvents?: boolean;
  onShowCancelledEventsClick?: () => void;
}) {
  const [selectedDate, setSelectedDate] = useState(props.selectedDate ?? DateTime.now());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showDrawerMenu = Boolean(anchorEl);

  const startOfDay = selectedDate.startOf('day');
  const endOfDay = selectedDate.endOf('day');

  const lessonsRequest = useGetLessons({
    fromDate: startOfDay.toMillis(),
    toDate: endOfDay.toMillis(),
  });

  const getTeacherShiftsRequest = useGetTeacherShifts({
    startsAt: startOfDay.toMillis(),
    endsAt: endOfDay.toMillis(),
  });

  const availabilityExceptionsRequest = useGetTeacherAvailabilityExceptions();

  const timeoff =
    availabilityExceptionsRequest.data
      ?.flatMap<TimeOffEvent>((exception) =>
        chunkTimeSpan(DateTime.fromMillis(exception.startsAt), DateTime.fromMillis(exception.endsAt)).map<TimeOffEvent>((ts) => ({
          id: exception.id,
          startTime: ts.startsAt,
          endTime: ts.endsAt,
        })),
      )
      .filter((ae) => ae.startTime >= startOfDay && ae.endTime <= endOfDay) ?? [];

  const handleGearClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setAnchorEl(null);
  };

  const handlePrevClick = () => {
    setSelectedDate((currentDate) => currentDate.minus({ day: 1 }));
  };

  const handleNextClick = () => {
    setSelectedDate((currentDate) => currentDate.plus({ day: 1 }));
  };

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <Stack padding="16px" sx={{ width: 380 }} role="presentation">
        <Stack direction="row" justifyContent="space-between">
          <IconButton variant="contained" onClick={props.onClose}>
            <Icon htmlColor={hootTokens.palette.white} name="close" />
          </IconButton>
          <IconButton onClick={handleGearClick}>
            <Icon name="gear" />
          </IconButton>
          <DrawerMenu
            open={showDrawerMenu}
            anchorEl={anchorEl}
            onClose={handleDrawerClose}
            showCancelledEvents={props.showCancelledEvents}
            onShowCancelledEventsClick={props.onShowCancelledEventsClick}
          />
        </Stack>
        <Stack marginTop="24px" direction="row" justifyContent="space-between">
          <IconButton onClick={handlePrevClick}>
            <Icon name="chevron" />
          </IconButton>
          <Stack alignItems="center">
            <Typography variant="displaysmall">{selectedDate.toFormat('LLLL d')}</Typography>
            <Typography variant="bodylarge">{selectedDate.toFormat('cccc')}</Typography>
          </Stack>
          <IconButton onClick={handleNextClick}>
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Stack>
        <DrawerShifts shifts={getTeacherShiftsRequest.data?.shifts} />
        <DrawerEvents lessons={lessonsRequest.data?.data} timeoff={timeoff} showCancelledEvents={props.showCancelledEvents ?? true} />
      </Stack>
    </Drawer>
  );
}

function DrawerMenu(props: {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  showCancelledEvents?: boolean;
  onShowCancelledEventsClick?: () => void;
}) {
  const handleShowCancelledEvents = () => {};

  return (
    <Menu
      id="teacher-schedule-drawer"
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleShowCancelledEvents}>
        <Checkbox onClick={props.onShowCancelledEventsClick} checked={props.showCancelledEvents} label="Show cancelled events" />
      </MenuItem>
    </Menu>
  );
}

function DrawerShifts(props: { shifts?: TeacherAccountShiftResponse[] }) {
  if (!props.shifts?.length) {
    return null;
  }

  return (
    <Stack>
      <Typography variant="titlemedium">Shifts</Typography>
      <Stack>
        {props.shifts?.map((s) => (
          <Card sx={{ marginTop: '16px' }} title="Shift">
            <ReadOnlyTextField
              label="Time"
              filledColour={getFilledColorForShift(s.status)}
              body={`${DateTime.fromMillis(s.startsAt).toFormat('h:mm a')} - ${DateTime.fromMillis(s.endsAt).toFormat('h:mm a')} `}
            />
            <ReadOnlyTextField
              sx={{ marginTop: '16px' }}
              filledColour={getFilledColorForShift(s.status)}
              label="Status"
              body={teacherShiftStatusLookup[s.status]}
            />
          </Card>
        ))}
      </Stack>
    </Stack>
  );
}

function DrawerEvents(props: { lessons?: LessonSearchResponse[]; timeoff?: TimeOffEvent[]; showCancelledEvents: boolean }) {
  if (!props.lessons?.length && !props.timeoff?.length) {
    return null;
  }

  return (
    <Stack marginTop="16px">
      <Typography variant="titlemedium">Events</Typography>
      <Stack>
        {props.lessons
          ?.filter((l) => (props.showCancelledEvents ? l : ![ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.status)))
          .map((l) => <LessonEventCard lesson={l} />)}
        {props.timeoff?.map((to) => <TimeOffEventCard timeoffEvent={to} />)}
      </Stack>
    </Stack>
  );
}

function LessonEventCard(props: { lesson: LessonSearchResponse }) {
  const dispatch = useAppDispatch();

  function filledColour(): ColorPaletteOption {
    switch (props.lesson.status) {
      case ScheduledLessonStatus.CompletedSuccessfully:
      case ScheduledLessonStatus.CompletedUnsuccessfully:
        return 'success.190';
      case ScheduledLessonStatus.Rescheduled:
      case ScheduledLessonStatus.Cancelled:
        return 'error.190';
      default:
        return 'secondary.190';
    }
  }

  const onCopyLessonNumber = () => {
    const lessonNumber = props.lesson.prefixedLessonNumber;
    navigator.clipboard.writeText(lessonNumber);
    dispatch(createFlashMessage({ message: `Copied "${lessonNumber}" to clipboard.` }));
  };

  const colour = filledColour();

  const studentFieldBody = () => {
    const isAccessible = props.lesson.studentId && props.lesson.studentName;
    return isAccessible ? (
      <span>
        <Link
          to={routesDictionary.myStudents.details.profile.url(props.lesson.studentId!)}
          state={{ navigateBackPath: routesDictionary.schedule.url }}
        >
          {props.lesson.prefixedStudentNumber}
        </Link>{' '}
        <HootTypography isPII={true} sx={{ display: 'inherit' }}>
          ({props.lesson.studentName})
        </HootTypography>
      </span>
    ) : (
      <HootTypography isPII={false} sx={{ display: 'inherit' }}>
        {props.lesson.prefixedStudentNumber}
      </HootTypography>
    );
  };

  return (
    <Card
      sx={{ marginTop: '16px' }}
      title={
        <Stack direction="row" alignItems="center">
          Lesson {props.lesson.prefixedLessonNumber}
          <IconButton size="small" sx={{ p: 0.75 }} onClick={onCopyLessonNumber}>
            <Icon name="copy" />
          </IconButton>
        </Stack>
      }
    >
      <ReadOnlyTextField label="Student" filledColour={colour} body={studentFieldBody()} />
      <ReadOnlyTextField
        sx={{ marginTop: '16px' }}
        filledColour={colour}
        label="Time"
        body={`${DateTime.fromMillis(props.lesson.startsAt).toFormat('h:mm a')} - ${DateTime.fromMillis(props.lesson.startsAt)
          .plus({ minutes: props.lesson.durationInMinutes })
          .toFormat('h:mm a')}`}
      />

      <ReadOnlyTextField sx={{ marginTop: '16px' }} filledColour={colour} label="Status" body={lessonStatusesLookup[props.lesson.status]} />
      {props.lesson.cancellationReason ? (
        <ReadOnlyTextField
          sx={{ marginTop: '16px' }}
          filledColour={colour}
          label="Cancellation Reason"
          body={cancellationReasonMessageLookup[props.lesson.cancellationReason]}
        />
      ) : null}
    </Card>
  );
}

function TimeOffEventCard(props: { timeoffEvent: TimeOffEvent }) {
  const queryClient = useQueryClient();
  const deleteTeacherAvailablityRequest = useDeleteTeacherAvailabilityException();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCloseModal = () => {
    setShowConfirmation(false);
  };

  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleOnDelete = () => {
    deleteTeacherAvailablityRequest.mutate(
      {
        teacherAvailabilityExceptionId: props.timeoffEvent.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.GetTeacherAvailabilityExceptions);
          queryClient.invalidateQueries(QueryKey.GetTeacherAvailablityExceptionDrafts);
        },
      },
    );
  };

  return (
    <Card sx={{ marginTop: '16px' }} title="Time Off">
      <ReadOnlyTextField
        label="Time"
        filledColour="warning.190"
        body={`${props.timeoffEvent.startTime.toFormat('h:mm a')} - ${props.timeoffEvent.endTime.toFormat('h:mm a')}`}
      />
      <Button onClick={handleDeleteClick} sx={{ marginTop: '16px' }} fullWidth color="error.80" variant="text" startIcon={<Icon name="trash_bin" />}>
        Delete Time Off
      </Button>
      {showConfirmation ? <DeleteConfirmation onDelete={handleOnDelete} onDismiss={handleCloseModal} /> : null}
    </Card>
  );
}

function DeleteConfirmation(props: { onDelete: () => void; onDismiss: () => void }) {
  return (
    <BasicAlertDialog
      title="Delete Confirmation"
      content="Are you sure you want to delete this Time Off"
      onDismiss={props.onDismiss}
      show={true}
      primaryAction={{ label: 'Delete', onClick: props.onDelete }}
      secondaryAction={{ label: 'Cancel', onClick: props.onDismiss }}
    />
  );
}

function getFilledColorForShift(status: TeacherShiftStatus): ColorPaletteOption {
  switch (status) {
    case TeacherShiftStatus.Cancelled:
      return 'error.190';
    case TeacherShiftStatus.Scheduled:
    case TeacherShiftStatus.Published:
      return 'secondary.190';
    case TeacherShiftStatus.Completed:
      return 'success.190';
  }
}

import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { StepTestAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import CloseSquare from '@hoot/ui/components/v2/icons/CloseSquare';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { RotateDirection } from '@hoot/ui/components/v2/utils/icon-utils';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { UnitQuestionAnswerPair } from '../PMAPage';
import { PMAFooter } from '../PMAPageFooter';
import UnitCompleteCard from '../UnitCompleteCard';
import { EditAnswerDialog } from '../dialogs/EditAnswerDialog';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';
import useTeacherPMAController from '../useTeacherPMAController';

/** INTERFACES */

interface Props {
  letterOptions: string[];
}

/** IStepProps */
interface IStepProps {
  onSelectedIndexChanged: (newIndex: number) => void;
  setShowEditAnswerDialog: (val: boolean) => void;
  selectedIndex: number | undefined;
  currentIndex: number;
  currentValue: string;
  currentAnswer: StepTestAnswer;
  unitQuestionAnswerPair: StepTestAnswer[];
  stepLength: number;
}

/** IStepTestProps */
interface IStepTestProps {
  prompt: string;
  allowedValues: string[];
  unitQuestionAnswerPair: UnitQuestionAnswerPair[];
  setUnitQuestionAnswerPair: React.Dispatch<React.SetStateAction<UnitQuestionAnswerPair[]>>;
}

/** COMPONENTS */

const PMA_QUESTIONNAIRE_LAYOUT_2 = (props: Props) => {
  const { letterOptions } = props;
  const [unitQuestionAnswerPair, setUnitQuestionAnswerPair] = useState<UnitQuestionAnswerPair[]>(
    letterOptions.map<UnitQuestionAnswerPair>((lo) => ({ fullPrompt: lo, wordPrompt: lo, answer: StepTestAnswer.NoAnswer })),
  );
  const { onUnitComplete } = useTeacherPMAController();
  const allQuestionsAnswered = unitQuestionAnswerPair.length > 0 && !unitQuestionAnswerPair.some((pair) => pair.answer === StepTestAnswer.NoAnswer);

  useEffect(() => {
    if (allQuestionsAnswered) {
      onUnitComplete(allQuestionsAnswered);
    }
  }, [allQuestionsAnswered]);

  return (
    <StepTest
      prompt={'Tell me what sound this letter makes:'}
      allowedValues={letterOptions}
      unitQuestionAnswerPair={unitQuestionAnswerPair}
      setUnitQuestionAnswerPair={setUnitQuestionAnswerPair}
    />
  );
};

/** Step */
export const Step = (props: IStepProps) => {
  const {
    currentValue,
    currentAnswer,
    unitQuestionAnswerPair,
    currentIndex,
    selectedIndex,
    onSelectedIndexChanged,
    stepLength,
    setShowEditAnswerDialog,
  } = props;

  const nextUnansweredQuestionIndex = useMemo(() => {
    return unitQuestionAnswerPair.findIndex((x) => x === StepTestAnswer.NoAnswer);
  }, [unitQuestionAnswerPair]);

  // You can not jump ahead and unitQuestionAnswerPair questions out of order. You can only go back to edit previously answered Qs.
  const isQuestionDisabled = nextUnansweredQuestionIndex >= 0 && currentIndex > nextUnansweredQuestionIndex;

  const handleLetterClick = () => () => {
    if (isQuestionDisabled) {
      return;
    }
    setShowEditAnswerDialog(true);
    onSelectedIndexChanged(currentIndex);
  };

  const renderCursorIcon = () => <PlayIcon rotate={RotateDirection.Up} sx={{ height: '24px', width: '16px' }} />;

  const renderAnswerIcon = () => {
    if (currentAnswer === StepTestAnswer.Correct) {
      return <CheckmarkSquare />;
    }
    if (currentAnswer === StepTestAnswer.Wrong) {
      return <CloseSquare />;
    }
  };

  const showIcon = () => {
    // Show cursor on clicked/selected letter.
    if (currentIndex === selectedIndex) {
      // If we're on the last Step.
      if (currentIndex + 1 === stepLength) {
        if (!currentAnswer) {
          return renderCursorIcon();
        }
      }
      return renderCursorIcon();
    }
    if (currentAnswer) {
      return renderAnswerIcon();
    }
    return null;
  };

  const value = currentValue.split(':');
  const isVisible = currentIndex < stepLength;

  return (
    <Box
      onClick={handleLetterClick()}
      sx={{
        visibility: isVisible ? 'visible' : 'hidden',
        display: 'flex',
        flex: '1',
        cursor: isQuestionDisabled ? 'not-allowed' : 'pointer',
        borderRight: '1px solid #CAC4D0',
        '&:last-child': {
          borderRight: '0px',
        },
      }}
    >
      <Stack style={{ flex: 1, alignItems: 'center', justifyContent: 'space-between', gap: '4px' }}>
        <HootTypography
          isPII={false}
          variant="bodylarge"
          sx={{
            color:
              currentAnswer === StepTestAnswer.Correct
                ? hootTokens.palette.success['60']
                : currentAnswer === StepTestAnswer.Wrong
                  ? hootTokens.palette.error['80']
                  : currentIndex <= nextUnansweredQuestionIndex
                    ? hootTokens.palette.black
                    : hootTokens.palette.neutral['140'],
          }}
        >
          {value[0]}
        </HootTypography>
        <Box width={24} height={24} display="flex" justifyContent="center">
          {showIcon()}
        </Box>
      </Stack>
    </Box>
  );
};

/** StepTest */
const StepTest = (props: IStepTestProps) => {
  const { prompt, allowedValues, unitQuestionAnswerPair, setUnitQuestionAnswerPair } = props;

  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const pmaController = useTeacherPMAController();

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const isNextEnabled = useSelector((state: RootState) => state.pma.isNextEnabled);
  const isPrevEnabled = useSelector((state: RootState) => state.pma.isPrevEnabled);

  const allFormFieldsFilledOut = unitQuestionAnswerPair.filter((uqap) => uqap.answer === StepTestAnswer.NoAnswer).length === 0;

  useSearchPMAUnitSubmissionData(assessmentID!, currentUnit, {
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (data.submissionData) {
        setUnitQuestionAnswerPair(data.submissionData as UnitQuestionAnswerPair[]);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const { onSetText } = useTeacherPMAController();
  const [currentValue, setCurrentValue] = useState<string>(allowedValues[0]);
  const [showEditAnswerDialog, setShowEditAnswerDialog] = useState<boolean>(false);

  const showAmount = allowedValues.length;
  const selectedIndex = allowedValues.indexOf(currentValue);
  useEffect(() => {
    onSetText(currentValue);
  }, [currentValue]);

  const isUnitComplete = useSelector((state: RootState) => state.pma.isUnitComplete);

  const handleSelectedValueChanged = (newIndex: number) => {
    const value = allowedValues[newIndex];
    setCurrentValue(value);
  };

  const handleAnswerSelect = (value: string, answer: StepTestAnswer) => {
    const indexOfValue = allowedValues.indexOf(value);
    const nextIndex = indexOfValue + 1;

    const updatedAnswers = unitQuestionAnswerPair.map((a) => {
      if (a.wordPrompt === value) {
        return { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: answer } as UnitQuestionAnswerPair;
      } else {
        return a;
      }
    });
    setUnitQuestionAnswerPair(updatedAnswers);
    setIsUnitQuestionnaireDirty(true);

    if (nextIndex < showAmount) {
      setCurrentValue(allowedValues[indexOfValue + 1]);
    } else {
      setCurrentValue('');
    }
  };

  const handleCancelEdit = () => {
    setCurrentValue('');
    setShowEditAnswerDialog(false);
  };

  const handleEditAnswer = (newAnswer: StepTestAnswer) => {
    const updatedAnswers = unitQuestionAnswerPair.map((a) => {
      if (a.wordPrompt === currentValue) {
        return { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: newAnswer } as UnitQuestionAnswerPair;
      } else {
        return a;
      }
    });
    setUnitQuestionAnswerPair(updatedAnswers);
    handleCancelEdit();
  };

  // Split the Questions into 2 chunks for the UI
  const allowedValuesMax = Math.floor(allowedValues.length / 2);
  const [firstChunk, secondChunk] = [allowedValues.slice(0, allowedValuesMax), allowedValues.slice(allowedValuesMax, allowedValues.length)];

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    setUnitQuestionAnswerPair([]);
    pmaController.onPrevUnit();
  };

  return (
    <>
      <Stack gap={4} paddingY={3}>
        <Stack
          sx={{
            gap: '16px',
            border: '1px solid black',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '24px',
          }}
        >
          {isUnitComplete ? (
            <UnitCompleteCard hasBeenSubmitted={false} />
          ) : (
            <>
              <Stack
                sx={{
                  borderRadius: '8px',
                  background: hootTokens.surface[2],
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingY: '16px',
                  width: '100%',
                }}
              >
                <HootTypography isPII={false} variant="bodymedium">
                  {prompt}
                </HootTypography>
                <HootTypography isPII={false} variant="displaymedium">
                  {currentValue}
                </HootTypography>
              </Stack>
              <Stack flexDirection="row" gap={2}>
                <Button
                  onClick={() => handleAnswerSelect(currentValue, StepTestAnswer.Wrong)}
                  variant="contained"
                  color="error.190"
                  disableElevation
                  startIcon={<CloseFilledSquareIcon />}
                >
                  Incorrect
                </Button>
                <Button
                  onClick={() => handleAnswerSelect(currentValue, StepTestAnswer.Correct)}
                  variant="contained"
                  color="success.190"
                  disableElevation
                  startIcon={<CheckmarkSquare />}
                >
                  Correct
                </Button>
              </Stack>
            </>
          )}
        </Stack>
        <Stack gap={2}>
          <HootTypography isPII={false} variant="tableheadingactive">
            Scorecard
          </HootTypography>
          <Stack gap={2}>
            <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
              {firstChunk.map((val, idx) => (
                <Step
                  key={val}
                  currentIndex={idx}
                  selectedIndex={selectedIndex}
                  onSelectedIndexChanged={handleSelectedValueChanged}
                  currentValue={val}
                  currentAnswer={unitQuestionAnswerPair.length > 0 ? unitQuestionAnswerPair[idx].answer : StepTestAnswer.NoAnswer}
                  unitQuestionAnswerPair={unitQuestionAnswerPair.map((a) => a.answer)}
                  stepLength={showAmount}
                  setShowEditAnswerDialog={setShowEditAnswerDialog}
                />
              ))}
            </Stack>
            {showAmount > allowedValuesMax && (
              <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
                {secondChunk.map((val, idx) => (
                  <Step
                    key={val}
                    currentIndex={idx + firstChunk.length}
                    selectedIndex={selectedIndex}
                    onSelectedIndexChanged={handleSelectedValueChanged}
                    currentValue={val}
                    currentAnswer={
                      unitQuestionAnswerPair.length > 0 ? unitQuestionAnswerPair[idx + firstChunk.length].answer : StepTestAnswer.NoAnswer
                    }
                    unitQuestionAnswerPair={unitQuestionAnswerPair.map((a) => a.answer)}
                    stepLength={showAmount}
                    setShowEditAnswerDialog={setShowEditAnswerDialog}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      <EditAnswerDialog
        open={showEditAnswerDialog}
        questionLabel={currentValue}
        defaultAnswer={unitQuestionAnswerPair[selectedIndex]?.answer ?? StepTestAnswer.NoAnswer}
        onSubmit={handleEditAnswer}
        onCancel={handleCancelEdit}
        setIsUnitQuestionnaireDirty={setIsUnitQuestionnaireDirty}
      />
      <PMAFooter
        allFormFieldsFilledOut={allFormFieldsFilledOut}
        isPreviousUnitDisabled={!isPrevEnabled}
        isThereAnotherUnit={isNextEnabled}
        onPreviousUnitClick={onPreviousUnitClick}
        setIsUnitQuestionnaireDirty={setIsUnitQuestionnaireDirty}
        unitQuestionAnswerPair={unitQuestionAnswerPair}
        setUnitQuestionAnswerPair={setUnitQuestionAnswerPair}
        setLeaveDialog={setLeaveDialog}
        isUnitQuestionnaireDirty={isUnitQuestionnaireDirty}
      />
      <LeaveCurrentUnitDialog open={leaveDialog} setOpen={setLeaveDialog} confirmLeave={confirmPrevUnitClick} />
    </>
  );
};

export default PMA_QUESTIONNAIRE_LAYOUT_2;

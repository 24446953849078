import { PMAFormVariant, PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Card, Collapse, IconButton, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useGetStudentProfileV2 from '@hoot/hooks/api/user/useGetStudentProfileV2';
import { StepTestAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PopoverMenu, { MenuItem } from '@hoot/ui/components/v2/core/PopoverMenu';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { useToolsContext } from '@hoot/ui/context/ToolsContext';
import { ReaderContext, readerKeyLookup, useReaders } from '../../../../../../redux/reducers/readingSlice';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { Coords, ZoomControl } from '../../../../../components/v2/library/ZoomControl';
import TeacherInLessonReaderPage from '../library/reader/TeacherInLessonReaderPage';
import useTeacherInLessonReaderController from '../library/reader/useTeacherInLessonReaderController';
import { Unit1Module1Example, Unit1Module2Example, Unit1Module3Example, Unit1Module4Example } from './UnitExample';
import LeavePMADialog from './dialogs/LeavePMADialog';
import PromptStudentGradeSelectionDialog from './dialogs/PromptStudentGradeSelectionDialog';
import {
  UNIT_1_1_FORM,
  UNIT_1_2_FORM,
  UNIT_1_3_FORM,
  UNIT_1_4_FORM,
  UNIT_1_5_FORM,
  UNIT_2_1_FORM,
  UNIT_2_2_FORM,
  UNIT_2_3_FORM,
  UNIT_2_4_FORM,
  UNIT_2_5_FORM,
  UNIT_2_6_FORM,
  UNIT_3_1_FORM,
  UNIT_3_2_FORM,
  UNIT_3_3_FORM,
  UNIT_3_4_FORM,
  UNIT_3_5_FORM,
  UNIT_3_6_FORM,
  UNIT_4_2_QUESTIONNAIRE,
  pmaInstructions,
  pmaTitles,
} from './enum';
import { PMA_QUESTIONNAIRE_LAYOUT_1 } from './units/PMA_QUESTIONNAIRE_LAYOUT_1';
import PMA_QUESTIONNAIRE_LAYOUT_2 from './units/PMA_QUESTIONNAIRE_LAYOUT_2';
import PMA_QUESTIONNAIRE_LAYOUT_3 from './units/PMA_QUESTIONNAIRE_LAYOUT_3';
import { PMA_QUESTIONNAIRE_LAYOUT_4 } from './units/PMA_QUESTIONNAIRE_LAYOUT_4';
import { PMA_QUESTIONNAIRE_LAYOUT_5 } from './units/PMA_QUESTIONNAIRE_LAYOUT_5';

// TODO_LUKA move this into hoot-core
export interface UnitQuestionAnswerPair {
  fullPrompt: string;
  wordPrompt: string;
  answer: StepTestAnswer;
  expectedAnswer?: string;
}

const PMAPage = () => {
  const { lessonId } = useParams();

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const controller = useTeacherInLessonReaderController();

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);

  const { inLesson } = useActiveLessonState();

  const dispatch = useAppDispatch();

  const { data: student } = useGetStudentProfileV2(inLesson?.studentId ?? '', {
    enabled: !!inLesson?.studentId,
    onError: (ex) => {
      console.error(ex);
      dispatch(createFlashMessage({ message: 'An error occurred while attempting to fetch the student data', variant: 'error' }));
    },
  });

  const doesStudentNotHaveGrade = !student?.grade;
  const [promptStudentGradeDialog, setPromptStudentGradeDialog] = useState<boolean>(doesStudentNotHaveGrade);

  const handleConfirmLeavePMA = () => {
    setShowConfirmDialog(false);
    if (lessonId) {
      controller.onNavigateToLibrary();
    }
  };

  const handleShowConfirmationClick = (showConfirmation: boolean) => {
    setShowConfirmDialog(showConfirmation);
  };

  const showBook = [PMAUnitIdentifier.Module_4_Unit_1, PMAUnitIdentifier.Module_4_Unit_3].includes(currentUnit);

  if (!student || !inLesson) {
    return null;
  }

  return (
    <Stack flex={1} sx={{ padding: 2, overflowY: 'scroll' }}>
      <Stack justifyContent="flex-start">
        <Stack justifyContent="flex-start">
          <AssessmentCard key={currentUnit} showToolsMenu={true} onShowConfirmationClick={handleShowConfirmationClick} />
        </Stack>
        {showBook ? <TeacherInLessonReaderPage /> : null}
        <LeavePMADialog open={showConfirmDialog} setOpen={setShowConfirmDialog} confirmLeave={handleConfirmLeavePMA} />
        <PromptStudentGradeSelectionDialog
          openDialog={promptStudentGradeDialog}
          setOpenDialog={setPromptStudentGradeDialog}
          studentName={student.name}
          confirmLeave={handleConfirmLeavePMA}
          studentId={inLesson.studentId}
        />
      </Stack>
    </Stack>
  );
};

function AssessmentCard(props: { showToolsMenu: boolean; onShowConfirmationClick: (showConfirmation: boolean) => void }) {
  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);

  return (
    <Card>
      <Stack>
        <Stack sx={{ height: '100%', gap: 1, justifyContent: 'flex-start', alignItems: 'left', paddingBottom: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <HootTypography isPII={false} variant="titlelarge">
              Progress Monitoring Assessment
            </HootTypography>
            <Stack direction="row">
              <ToolsMenu onShowConfirmationClick={props.onShowConfirmationClick} currentUnitId={currentUnit} />
            </Stack>
          </Stack>
          <HootTypography isPII={false} variant="bodylarge">
            {pmaTitles[currentUnit]}
          </HootTypography>
          <Instructions />
          <Content />
        </Stack>
      </Stack>
    </Card>
  );
}

function ToolsMenu(props: { onShowConfirmationClick: (showConfirmation: boolean) => void; currentUnitId: PMAUnitIdentifier }) {
  const {
    isCelebrationsDialogVisible,
    onShowCelebrationsDialog,
    onHideCelebrationsDialog,
    isNotepadVisible,
    onShowNotepad,
    onHideNotepad,
    isTimerVisible,
    onShowTimer,
    onHideTimer,
  } = useToolsContext();

  const readerKey = readerKeyLookup[ReaderContext.InLessonReader];
  const reader = useReaders()[readerKey];

  const controller = useTeacherInLessonReaderController();

  const [showZoomControl, setShowZoomControl] = useState(false);
  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const onToggleFullscreen = async () => {
    dismissToolsMenu();
    await toggleFullscreen();
  };

  const toggleShowTimer = () => {
    if (isTimerVisible) {
      onHideTimer();
    } else {
      onShowTimer();
    }
    dismissToolsMenu();
  };

  const toggleShowNotepad = () => {
    if (isNotepadVisible) {
      onHideNotepad();
    } else {
      onShowNotepad();
    }
    dismissToolsMenu();
  };

  const toggleCelebrationsDialog = () => {
    if (isCelebrationsDialogVisible) {
      onHideCelebrationsDialog();
    } else {
      onShowCelebrationsDialog();
    }
    dismissToolsMenu();
  };

  const onZoomClick = () => {
    setShowZoomControl(true);
    dismissToolsMenu();
  };

  const [toolsIconButtonElement, setToolsIconButtonElement] = useState<null | HTMLElement>(null);

  const dismissToolsMenu = () => {
    setToolsIconButtonElement(null);
  };

  const isModule4 = false; // [PMAForms.Unit_4_1, PMAForms.Unit_4_3A, PMAForms.Unit_4_3B].includes(props.form);
  const disableDoublePage = !isModule4;

  const showToolsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToolsIconButtonElement(event.currentTarget);
  };

  const doublePageToolTip = () => {
    if (!isModule4) {
      return 'Double Page Disabled for this Module';
    }

    return 'Show Double Page View';
  };

  const handleShowConfirmationClick = () => {
    props.onShowConfirmationClick(true);
  };

  const handleDoublePage = () => {
    controller.onToggleDoublePage();
  };

  const toolsMenuItems = (): MenuItem[] => {
    const toolItemsArray = [
      {
        id: 'go-fullscreen',
        label: isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen',
        icon: <Icon name={isFullscreen ? 'exit_full_screen' : 'enter_full_screen'} />,
        onClick: onToggleFullscreen,
      },
      { id: 'zoom', label: 'Zoom', icon: <Icon name="zoom_in" />, onClick: onZoomClick },
      {
        id: 'notepad',
        label: 'Notepad',
        icon: <Icon name="pen" />,
        onClick: toggleShowNotepad,
      },
      { id: 'timer', label: 'Timer', icon: <Icon name="clock" />, onClick: toggleShowTimer },
      {
        id: 'celebrations',
        label: 'Celebrations',
        icon: <Icon name="hoot_wheel" />,
        onClick: toggleCelebrationsDialog,
      },
    ];

    if (
      ![
        PMAUnitIdentifier.Module_4_Unit_1,
        PMAUnitIdentifier.Module_4_Unit_2,
        PMAUnitIdentifier.Module_4_Unit_3,
        PMAUnitIdentifier.Module_4_Unit_4,
      ].includes(props.currentUnitId)
    ) {
      // remove the zoom control if not on module 4 units
      toolItemsArray.splice(1, 1);
    }

    return toolItemsArray;
  };

  const {
    inLessonReader: { book, pageIndex, isDoublePage, pageZoom },
  } = useReaders();

  const zoomBoxCoordsRef = useRef<Coords>({ x: 0, y: 0 });

  const pagesToShow = book?.pages.slice(pageIndex, pageIndex + (isDoublePage ? 2 : 1)).map((p) => p.url) ?? [];

  return (
    <Stack direction="row" gap={2}>
      <Tooltip title="Show Tools Menu">
        <IconButton onClick={showToolsMenu}>
          <Icon name="compass" />
        </IconButton>
      </Tooltip>
      <Tooltip title={doublePageToolTip()}>
        <span>
          <IconButton disabled={!isModule4} onClick={handleDoublePage}>
            <Icon name={reader.isDoublePage ? 'book_double' : 'single_page'} color={disableDoublePage ? 'primary.80' : 'primary'} />
          </IconButton>
        </span>
      </Tooltip>
      <IconButton onClick={handleShowConfirmationClick}>
        <Icon name={'close_filled_square'} color={'error.80'} sx={{ background: 'error.80' }} />
      </IconButton>

      <PopoverMenu
        id="tools-menu"
        variant="dark"
        anchorEl={toolsIconButtonElement}
        open={!!toolsIconButtonElement}
        onClose={dismissToolsMenu}
        items={toolsMenuItems()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      {showZoomControl ? (
        <ZoomControl
          onClose={() => setShowZoomControl(false)}
          pages={pagesToShow}
          onReset={controller.onResetZoom}
          onZoomChange={controller.onZoomAmountChange}
          onOffsetChange={controller.onZoomOffsetChange}
          zoomValue={pageZoom.zoomAmount * 100}
          zoomBoxCoordsRef={zoomBoxCoordsRef}
          onToggleDoublePage={controller.onToggleDoublePage}
          readerContext={ReaderContext.InLessonReader}
        />
      ) : null}
    </Stack>
  );
}

function Instructions() {
  const [openInstructions, setOpenInstructions] = useState<boolean>(false);

  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);

  const handleClick = () => {
    setOpenInstructions((prevVal) => !prevVal);
  };

  function ShowExample() {
    if (currentUnit === PMAUnitIdentifier.Module_1_Unit_1) {
      return <Unit1Module1Example />;
    } else if (currentUnit === PMAUnitIdentifier.Module_1_Unit_2) {
      return <Unit1Module2Example />;
    } else if (currentUnit === PMAUnitIdentifier.Module_1_Unit_3) {
      return <Unit1Module3Example />;
    } else if (currentUnit === PMAUnitIdentifier.Module_1_Unit_4) {
      return <Unit1Module4Example />;
    }
    return null;
  }

  return (
    <>
      <Button
        startIcon={
          openInstructions ? (
            <Icon name="collapse_filled" sx={{ width: 200, height: 20 }} />
          ) : (
            <Icon name="solid_add_rectangle" htmlColor="primary.0" />
          )
        }
        onClick={handleClick}
        sx={{ width: '150px', gap: '8px' }}
      >
        <HootTypography isPII={false} variant="titlesmall">
          Instructions
        </HootTypography>
      </Button>
      <Collapse in={openInstructions} timeout="auto" unmountOnExit>
        <HootTypography isPII={false} variant="bodylarge">
          {pmaInstructions[currentUnit]}
        </HootTypography>

        <ShowExample />
      </Collapse>
    </>
  );
}

function Content() {
  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const formVariant = PMAFormVariant.B;

  switch (currentUnit) {
    case PMAUnitIdentifier.Module_1_Unit_1:
      return <PMA_QUESTIONNAIRE_LAYOUT_1 key={currentUnit} prompts={UNIT_1_1_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_1_Unit_2:
      return <PMA_QUESTIONNAIRE_LAYOUT_1 key={currentUnit} prompts={UNIT_1_2_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_1_Unit_3:
      return <PMA_QUESTIONNAIRE_LAYOUT_1 key={currentUnit} prompts={UNIT_1_3_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_1_Unit_4:
      return <PMA_QUESTIONNAIRE_LAYOUT_1 key={currentUnit} prompts={UNIT_1_4_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_1_Unit_5:
      return <PMA_QUESTIONNAIRE_LAYOUT_2 key={currentUnit} letterOptions={UNIT_1_5_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_1:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_2_1_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_2:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_2_2_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_3:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_2_3_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_4:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_2_4_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_5:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_2_5_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_6:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_2_6_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_1:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_3_1_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_2:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_3_2_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_3:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_3_3_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_4:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_3_4_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_5:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_3_5_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_6:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} prompts={UNIT_3_6_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_4_Unit_1:
      return <PMA_QUESTIONNAIRE_LAYOUT_4 key={currentUnit} />;
    case PMAUnitIdentifier.Module_4_Unit_2:
      return <PMA_QUESTIONNAIRE_LAYOUT_5 key={currentUnit} questionnaire={UNIT_4_2_QUESTIONNAIRE} />;
    case PMAUnitIdentifier.Module_4_Unit_3:
      return <PMA_QUESTIONNAIRE_LAYOUT_5 key={currentUnit} questionnaire={UNIT_4_2_QUESTIONNAIRE} />;
    case PMAUnitIdentifier.Module_4_Unit_4:
      return <PMA_QUESTIONNAIRE_LAYOUT_5 key={currentUnit} questionnaire={UNIT_4_2_QUESTIONNAIRE} />;
  }
}

export default PMAPage;

export enum TimelineEvents {
  HootReadingAssessment = 'Hoot Reading Assessment',
  ProgressMonitoringAssessment = 'Progress Monitoring Assessment',
  CompletedLesson = 'Completed Lesson',
  ScheduledLesson = 'Scheduled Lesson',
  StudentAbsence = 'Student Absence',
  Cancellation = 'Cancellation',
}

export enum AssessmentOutcomes {
  Mastered = 'Mastered',
  Passed = 'Passed',
  ReviewRequired = 'Review Required',
  InstructionRequired = 'Instruction Required',
}

export enum LessonReviewFocuses {
  PreviouslyPassedOrMastered = 'Previously Passed/Mastered',
  LessonPrimaryFocus = 'Lesson Primary Focus',
  LessonSecondaryFocus = 'Lesson Secondary Focus',
  FormativeAssessment = 'Formative Assessment',
}

import { useCallback, useEffect, useState } from 'react';
import {
  DefaultColorStyle,
  DefaultFontStyle,
  DefaultSizeStyle,
  Editor,
  HistoryEntry,
  StoreListener,
  TLDrawShape,
  TLGeoShape,
  TLRecord,
  TLStoreWithStatus,
  TLUnknownShape,
  Vec,
  createShapeId,
  createTLStore,
  loadSnapshot,
  throttle,
} from 'tldraw';
import { EventType } from '@hoot/events/eventType';
import { WhiteboardTool, WhiteboardToolColour } from '@hoot/events/interfaces/whiteboard.state';
import { GetWhiteboardSnapshotResponseMessage } from '@hoot/events/messages/get-whiteboard-snapshot-response.message';
import { GetWhiteboardStateResponseMessage } from '@hoot/events/messages/get-whiteboard-state-response.message';
import { WhiteboardGetSnapshotRequestMessage } from '@hoot/events/messages/whiteboard-get-snapshot-request.message';
import { WhiteboardGetStateRequestMessage } from '@hoot/events/messages/whiteboard-get-state-request.message';
import { WhiteboardModifyRequestMessage } from '@hoot/events/messages/whiteboard-modify-request.message';
import { WhiteboardRecenterResponseMessage } from '@hoot/events/messages/whiteboard-recenter-response.message';
import { WhiteboardSyncMessage } from '@hoot/events/messages/whiteboard-sync.message';
import { useEmit } from '@hoot/hooks/useEmit';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { handleSyncWhiteboard } from '@hoot/redux/reducers/whiteboardSlice';
import { useAppDispatch } from '@hoot/redux/store';

const BOUNDARY_FRAME_ID = 'boundaryFrameId';

// Publishes a batch of whiteboard events every X milliseconds.
const updateEmitterThrottleTimeMs = 50;

const cameraRecenterAnimationTimeMs = 500;

interface UseWhiteboardManagerProps {
  lessonId: string;
  whiteboardId: string;
  editor: Editor | undefined;
  currentTool: WhiteboardTool;
  currentToolColour: WhiteboardToolColour;
  readOnlyMode?: boolean;
}

/**
 * In-lesson whiteboard manager for teacher and student.
 */
export const useWhiteboardManager = (props: UseWhiteboardManagerProps) => {
  const { lessonId, whiteboardId, editor, currentTool, currentToolColour, readOnlyMode = false } = props;

  const dispatch = useAppDispatch();

  const [store] = useState(createTLStore());
  // The `store` state above will eventually be fed into this once a store snapshot response is received.
  const [storeWithStatus, setStoreWithStatus] = useState<TLStoreWithStatus>({
    status: 'loading',
  });
  const status = storeWithStatus.status;

  const getWhiteboardStateRequestEmitter = useEmit<WhiteboardGetStateRequestMessage>(EventType.WhiteboardGetStateRequest, undefined, {
    enabled: false,
  });
  const getWhiteboardSnapshotRequestEmitter = useEmit<WhiteboardGetSnapshotRequestMessage>(EventType.WhiteboardGetSnapshotRequest, undefined, {
    enabled: false,
  });

  // Emit local changes from the Tldraw canvas to the API.
  const clientUpdateEmitter = useEmit<WhiteboardModifyRequestMessage>(EventType.WhiteboardModifyRequest, undefined, { enabled: false });

  // On page-load, request the state and latest snapshot of the whiteboard.
  useEffect(() => {
    getWhiteboardStateRequestEmitter.emit({
      lessonId,
      whiteboardId,
    });
    getWhiteboardSnapshotRequestEmitter.emit({
      lessonId,
      whiteboardId,
    });
  }, [lessonId, whiteboardId]);

  // Handle whiteboard state changes (not including snapshot). We're handling metadata changes here.
  useSocketSubscription<GetWhiteboardStateResponseMessage>(EventType.WhiteboardGetStateResponse, {
    onEventReceived: (response) => {
      dispatch(handleSyncWhiteboard(response.whiteboardState));
    },
  });

  // When we receive the _full_ whiteboard snapshot response, load it up into the Tldraw canvas.
  useSocketSubscription<GetWhiteboardSnapshotResponseMessage>(EventType.WhiteboardGetSnapshotResponse, {
    onEventReceived: (response) => {
      const storeSnapshot = response.storeSnapshot;

      // If no snapshot was returned, then there's a problem.
      if (!storeSnapshot) {
        console.log('Whiteboard response received, but missing snapshot. Bailing out.');
        setStoreWithStatus({
          status: 'not-synced',
          store,
        });
        return;
      }
      console.log('Whiteboard snapshot received. Loading canvas...');
      // Else, we have a snapshot that we can pass that into the tldraw canvas.
      loadSnapshot(store, { document: storeSnapshot });

      // If a snapshot has been reloaded (this can happen if the whiteboard has been reset), then zoom to fit the board,
      // else we might only see a portion of the loaded template.
      editor?.zoomToFit({ force: true });

      // Set connection status.
      setStoreWithStatus({
        status: 'synced-remote',
        connectionStatus: 'online',
        store,
      });
    },
  });

  // When we're ready (everything is initialized, and we have a valid snapshot), create a handler for local changes on
  // the Tldraw canvas, and push these changes over to the API for the other participant to receive.
  useEffect(() => {
    if (!whiteboardId || !lessonId || !store || status !== 'synced-remote') {
      return;
    }
    // Listen for whiteboard changes, and publish them to the API.
    const pendingChanges: HistoryEntry<TLRecord>[] = [];

    const sendChanges = throttle(() => {
      if (pendingChanges.length === 0) return;

      // Publish updates.
      clientUpdateEmitter.emit({
        whiteboardId,
        lessonId,
        pendingUpdates: pendingChanges,
      });
      pendingChanges.length = 0;
    }, updateEmitterThrottleTimeMs);

    const handleChange: StoreListener<TLRecord> = (event) => {
      if (event.source !== 'user') return;
      pendingChanges.push(event);
      sendChanges();
    };

    const unsubs = [
      store.listen(handleChange, {
        source: 'user',
        scope: 'document',
      }),
      store.listen(handleChange, {
        source: 'user',
        scope: 'presence',
      }),
    ];

    // Unsubscribe events.
    return () => {
      unsubs.forEach((fn) => fn());
    };
  }, [whiteboardId, lessonId, store, status]);

  // Listen for incoming whiteboard _changes_ (we're not getting the full-blown snapshot here), and sync them into our local store.
  // Note: All incoming lesson-specific messages are handled in `useLessonMessageHandler` _except_ in this case. We need
  // direct access to the whiteboard store when receiving whiteboard events.
  useSocketSubscription<WhiteboardSyncMessage>(EventType.WhiteboardSyncResponse, {
    onEventReceived: (response) => {
      // We need to be ready to accept these changes before we can merge anything into the whiteboard.
      if (status !== 'synced-remote') {
        return;
      }
      try {
        for (const update of response.updates) {
          store.mergeRemoteChanges(() => {
            const {
              changes: { added, updated, removed },
            } = update as HistoryEntry<TLRecord>;

            for (const record of Object.values(added)) {
              store.put([record]);
            }
            for (const [, to] of Object.values(updated)) {
              store.put([to]);
            }
            for (const record of Object.values(removed)) {
              store.remove([record.id]);
            }
          });
        }
      } catch (e) {
        // If we failed to sync the canvas, then request a new snapshot.
        console.error('Tldraw merge error. Re-requesting snapshot...', e);
        getWhiteboardSnapshotRequestEmitter.emit({
          lessonId,
          whiteboardId: response.whiteboardId,
        });
      }
    },
  });

  // Re-center whiteboard camera handler.
  useSocketSubscription<WhiteboardRecenterResponseMessage>(EventType.WhiteboardRecenterResponse, {
    onEventReceived: () => {
      editor?.zoomToFit({ force: true, animation: { duration: cameraRecenterAnimationTimeMs } });
    },
  });

  /**
   * Select the tool and colour from the remote whiteboard state.
   * Note: If the whiteboard state request hasn't come back yet, then the currentTool and currentToolColour will just be the defaults.
   */
  const _setWhiteboardTool = useCallback(() => {
    if (!editor) return;

    // If we're in readonly mode, then don't both changing the active tool. We can't edit the whiteboard.
    if (readOnlyMode) return;

    let tool = currentTool;
    if (!Object.values(WhiteboardTool).includes(tool)) {
      console.warn(`Invalid whiteboard tool requested "${tool}". Defaulting to "${WhiteboardTool.Draw}".`);
      tool = WhiteboardTool.Draw;
    }
    let toolColour = currentToolColour;
    if (!Object.values(WhiteboardTool).includes(tool)) {
      console.warn(`Invalid whiteboard tool colour requested "${toolColour}". Defaulting to "${WhiteboardToolColour.Black}".`);
      toolColour = WhiteboardToolColour.Black;
    }
    editor.setCurrentTool(tool);
    editor.setStyleForNextShapes(DefaultColorStyle, toolColour);

    switch (tool) {
      case WhiteboardTool.Select:
      case WhiteboardTool.Text:
        editor.setStyleForNextShapes(DefaultSizeStyle, 'xl');
        editor.setStyleForNextShapes(DefaultFontStyle, 'sans');
        break;
      case WhiteboardTool.Draw:
        editor.setStyleForNextShapes(DefaultSizeStyle, 'l');
        break;
      case WhiteboardTool.Eraser:
        // Nothing to change here.
        break;
    }
  }, [editor, currentTool, currentToolColour, readOnlyMode]);

  /**
   * Enable/disable readonly mode.
   */
  const _setWriteMode = useCallback(() => {
    if (!editor) return;

    editor.updateInstanceState({ isReadonly: readOnlyMode });

    // If we're in re-only mode, then we'll override the selected tool (just locally) to be the laser pointer.
    if (readOnlyMode) {
      editor.setCurrentTool('laser');
    } else {
      // Else if we can edit the whiteboard, then make sure we're using the right tool.
      _setWhiteboardTool();
    }
  }, [readOnlyMode, editor, _setWhiteboardTool]);

  // Handle tool change.
  useEffect(() => {
    _setWhiteboardTool();
  }, [_setWhiteboardTool]);

  // Handle readonly mode toggled.
  useEffect(() => {
    _setWriteMode();
  }, [_setWriteMode]);

  return {
    store: storeWithStatus,
    onEditorReady: (editor: Editor) => {
      // When the editor is ready, reset the camera position so that we can see the entire whiteboard template.
      // If the template hasn't been loaded yet, then this does nothing.
      editor.zoomToFit({ force: true });

      _setWhiteboardTool();
      _setWriteMode();

      // Register a handler so that we don't delete any shapes that are part of the template.
      editor.sideEffects.registerBeforeDeleteHandler('shape', (shape) => {
        if (!(shape.meta?.isDeletable ?? true)) {
          return false;
        }
        return;
      });

      // Register a handler to ensure that new records added to the canvas are always within bounds (if a boundary rect exists)
      editor.sideEffects.registerBeforeCreateHandler('shape', (record, source) => {
        if (source !== 'user') return record;

        const boundaryFrameId = createShapeId(BOUNDARY_FRAME_ID);
        const boundaryFrame = editor.getShape<TLGeoShape>(boundaryFrameId);

        // If there is no boundary shape, then bail. We don't need to do anything.
        if (!boundaryFrame) return record;

        // Get the coordinates of the new record.
        const point = Vec.From(record);

        const boundaryAbsMinX = boundaryFrame.x;
        const boundaryAbsMaxX = boundaryFrame.x + boundaryFrame.props.w;
        const boundaryAbsMinY = boundaryFrame.y;
        const boundaryAbsMaxY = boundaryFrame.y + boundaryFrame.props.h;

        // The x-y coordinates of the new record must be within bounds.
        const newX = point.x < boundaryAbsMinX ? boundaryFrame.x : point.x > boundaryAbsMaxX ? boundaryAbsMaxX : point.x;
        const newY = point.y < boundaryAbsMinY ? boundaryAbsMinY : point.y > boundaryAbsMaxY ? boundaryAbsMaxY : point.y;

        return {
          ...record,
          x: newX,
          y: newY,
        };
      });

      // Register a handler to ensure that updated records are always within bounds (if a boundary rect exists)
      editor.sideEffects.registerBeforeChangeHandler('shape', (prev, next, source) => {
        if (source !== 'user') return next;

        // Programmatically disallowing shape rotation. Would be more optimal to just hide the "rotate" function
        // from the UI, but couldn't find how to do that.
        if (next.rotation !== prev.rotation) {
          return prev;
        }

        const boundaryFrameId = createShapeId(BOUNDARY_FRAME_ID);
        const boundaryFrame = editor.getShape<TLGeoShape>(boundaryFrameId);

        // If there is no boundary shape, then bail. We don't need to do anything.
        if (!boundaryFrame) return next;

        if (next.type === 'draw') {
          const drawShape = next as TLDrawShape;
          const prevDrawShape = prev as TLDrawShape;

          const drawSegments = drawShape.props.segments;

          // If we don't have _exactly_ one segment (there is only one segment when drawing freehand), then bail else the following logic won't work.
          if ((drawSegments?.length ?? 0) !== 1) return next;

          const prevPointCount = prevDrawShape.props.segments.flatMap((s) => s.points).length;
          const currPointCount = drawSegments.flatMap((s) => s.points).length;
          const pointCountDelta = currPointCount - prevPointCount;

          switch (pointCountDelta) {
            /**
             * If there are _no_ new points than there was previously, then we're either translating or resizing the shape (we're not drawing).
             */
            case 0: {
              return keepShapeInsideFrame(next, boundaryFrame, editor);
            }
            /**
             * If we are drawing freehand, then there will be exactly one more point than there was previously.
             * Ensure that the newest point stays within bounds.
             */
            case 1: {
              return keepDrawPointsInsideFrame(drawShape, boundaryFrame);
            }
            default: {
              return next;
            }
          }
        } else {
          return keepShapeInsideFrame(next, boundaryFrame, editor);
        }
      });
    },
  };
};

const keepDrawPointsInsideFrame = <T extends TLDrawShape>(drawShape: T, frame: TLGeoShape): T => {
  // The min and max coordinates of the frame in absolute space.
  const frameBoundsAbsMinX = frame.x;
  const frameBoundsAbsMaxX = frame.x + frame.props.w;
  const frameBoundsAbsMinY = frame.y;
  const frameBoundsAbsMaxY = frame.y + frame.props.h;

  // The starting position of the entire modified shape in absolute space.
  let shapeAbsOriginX = drawShape.x;
  let shapeAbsOriginY = drawShape.y;

  const updatedSegments = drawShape.props.segments.map((segment) => {
    // For each segment, ensure that last drawn point is within bounds.
    const points = [...segment.points];

    // If there aren't any points in this segment, then bail.
    if (!points?.length || points.length < 1) return segment;

    const lastRelPoint = points[points.length - 1];

    // Based on the origin, figure out what the min and max relative coordinates can be.
    const relMaxX = frameBoundsAbsMaxX - shapeAbsOriginX;
    const relMinX = frameBoundsAbsMinX - shapeAbsOriginX;
    const relMaxY = frameBoundsAbsMaxY - shapeAbsOriginY;
    const relMinY = frameBoundsAbsMinY - shapeAbsOriginY;

    // Ensure the last point is within bounds.
    points[points.length - 1] = {
      x: lastRelPoint.x > relMaxX ? relMaxX : lastRelPoint.x < relMinX ? relMinX : lastRelPoint.x,
      y: lastRelPoint.y > relMaxY ? relMaxY : lastRelPoint.y < relMinY ? relMinY : lastRelPoint.y,
      z: lastRelPoint.z,
    };
    return {
      ...segment,
      points,
    };
  });
  return {
    ...drawShape,
    props: {
      ...drawShape.props,
      segments: updatedSegments,
    },
  };
};

const keepShapeInsideFrame = <T extends TLUnknownShape>(shape: T, frame: TLGeoShape, editor: Editor): T => {
  // The min and max coordinates of the frame in absolute space.
  const frameBoundsAbsMinX = frame.x;
  const frameBoundsAbsMaxX = frame.x + frame.props.w;
  const frameBoundsAbsMinY = frame.y;
  const frameBoundsAbsMaxY = frame.y + frame.props.h;

  // The starting position of the entire modified shape in absolute space.
  let shapeAbsOriginX = shape.x;
  let shapeAbsOriginY = shape.y;

  const shapeUtils = editor.getShapeUtil(shape);
  const shapeGeometry = shapeUtils.getGeometry(shape);
  const shapeRelBounds = shapeGeometry.getBounds();

  const shapeAbsMinX = shapeAbsOriginX + shapeRelBounds.minX;
  const shapeAbsMaxX = shapeAbsOriginX + shapeRelBounds.maxX;
  const shapeAbsMinY = shapeAbsOriginY + shapeRelBounds.minY;
  const shapeAbsMaxY = shapeAbsOriginY + shapeRelBounds.maxY;

  let newAbsOriginX = shapeAbsOriginX;
  let newAbsOriginY = shapeAbsOriginY;

  // If shape has gone past the LEFT boundary
  if (shapeAbsMinX < frameBoundsAbsMinX) {
    newAbsOriginX = frameBoundsAbsMinX - shapeRelBounds.minX;
  }
  // If shape has gone past the RIGHT boundary
  if (shapeAbsMaxX > frameBoundsAbsMaxX) {
    newAbsOriginX = frameBoundsAbsMaxX - shapeRelBounds.maxX;
  }
  // If shape has gone past the TOP boundary
  if (shapeAbsMinY < frameBoundsAbsMinY) {
    newAbsOriginY = frameBoundsAbsMinY - shapeRelBounds.minY;
  }
  // If shape has gone past the BOTTOM boundary
  if (shapeAbsMaxY > frameBoundsAbsMaxY) {
    newAbsOriginY = frameBoundsAbsMaxY - shapeRelBounds.maxY;
  }
  return {
    ...shape,
    x: newAbsOriginX,
    y: newAbsOriginY,
  };
};

import { Fade, Stack } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Editor } from 'tldraw';
import { WhiteboardTool, WhiteboardToolColour } from '@hoot/events/interfaces/whiteboard.state';
import { useWhiteboardManager } from '@hoot/hooks/lessons/useWhiteboardManager';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { RootState } from '@hoot/redux/store';
import Whiteboard from '@hoot/ui/components/v2/Whiteboard';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import TeacherInLessonWhiteboardHeader from '@hoot/ui/pages/v2/teacher/lesson/whiteboard/TeacherInLessonWhiteboardHeader';
import useTeacherInLessonWhiteboardController from '@hoot/ui/pages/v2/teacher/lesson/whiteboard/useTeacherInLessonWhiteboardController';

const TeacherInLessonWhiteboardPage = () => {
  usePageTitle('Whiteboard | Hoot Reading');

  const [editor, setEditor] = useState<Editor>();
  const [showResetWhiteboardDialog, setShowResetWhiteboardDialog] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const activeLessonState = useActiveLessonState();
  const lessonId = activeLessonState.inLesson!.lessonId;
  const whiteboardId = activeLessonState.activeWhiteboardId!; // This must exist, else we wouldn't be on this page right now.

  const currentTool = useSelector((state: RootState) => {
    return state.whiteboard.whiteboard?.currentTool ?? WhiteboardTool.Select;
  });
  const currentToolColour = useSelector((state: RootState) => {
    return state.whiteboard.whiteboard?.currentColour ?? WhiteboardToolColour.Black;
  });

  const whiteboardController = useTeacherInLessonWhiteboardController({
    lessonId,
    whiteboardId,
  });

  const syncManager = useWhiteboardManager({
    lessonId,
    whiteboardId,
    editor,
    currentTool,
    currentToolColour,
  });

  const onShowResetWhiteboardDialog = () => setShowResetWhiteboardDialog(true);
  const onDismissResetWhiteboardDialog = () => setShowResetWhiteboardDialog(false);

  const onShowConfirmCloseDialog = () => setShowCloseConfirmation(true);
  const onDismissConfirmCloseDialog = () => setShowCloseConfirmation(false);

  const onEditorReady = (editor: Editor) => {
    setEditor(editor);
    syncManager.onEditorReady(editor);
  };

  const onResetWhiteboard = () => {
    whiteboardController.onResetWhiteboard();
    onDismissResetWhiteboardDialog();
  };

  const onCloseWhiteboard = () => {
    whiteboardController.closeWhiteboard();
    onDismissConfirmCloseDialog();
  };

  return (
    <Fade in>
      <Stack sx={{ width: '100%', height: '100%', gap: 3 }}>
        <TeacherInLessonWhiteboardHeader
          editor={editor}
          currentTool={currentTool}
          currentToolColour={currentToolColour}
          onRequestToolChange={whiteboardController.onRequestToolChange}
          onCloseWhiteboard={onShowConfirmCloseDialog}
          onResetWhiteboard={onShowResetWhiteboardDialog}
          onRequestRecenter={whiteboardController.onRequestRecenter}
        />
        <Whiteboard store={syncManager.store} onEditorReady={onEditorReady} />
        {/* Dialogs */}
        <BasicAlertDialog
          show={showResetWhiteboardDialog}
          onDismiss={onDismissResetWhiteboardDialog}
          title="Reset Whiteboard?"
          content="The whiteboard will be restored to its original state."
          primaryAction={{
            label: 'Reset',
            onClick: onResetWhiteboard,
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: onDismissResetWhiteboardDialog,
          }}
        />
        <BasicAlertDialog
          show={showCloseConfirmation}
          onDismiss={onDismissConfirmCloseDialog}
          title="Are you sure you want to return to the library?"
          content={<></>}
          primaryAction={{
            label: 'Yes, take me back to the library',
            onClick: onCloseWhiteboard,
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: onDismissConfirmCloseDialog,
          }}
        />
      </Stack>
    </Fade>
  );
};

export default TeacherInLessonWhiteboardPage;

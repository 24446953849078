import { Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import TimelineLegendDialog from './TimelineLegendDialog';

interface TimelineButtonProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: ReactNode;
}

const TimelineButton = (props: TimelineButtonProps) => {
  return (
    <Button onClick={props.onClick} variant="contained" color="neutral.180" startIcon={props.icon ?? undefined}>
      {props.text}
    </Button>
  );
};

const StudentTimelinePage = () => {
  const { _studentProfileId } = useParams();

  const [open, setOpen] = useState<boolean>(false);

  const _handleTimelineFilterClick = () => console.log('TODO:SC-11933');
  const _handleFocusVisibilityClick = () => console.log('TODO:SC-11933');
  const _handleEventVisibilityClick = () => console.log('TODO:SC-11933');
  const toggleLegendDialog = () => setOpen((prev) => !prev);

  return (
    <>
      <PageLayout sx={{ height: 1 }}>
        <Card title="Student Timeline" sx={{ height: 1 }}>
          <Stack gap={2}>
            <Stack direction="row" justifyItems="flex-start" textAlign="center" gap={2}>
              <TimelineButton text="Timeline Filter" onClick={_handleTimelineFilterClick} icon={<Icon name="calendar" />} />
              <TimelineButton text="Focus Visibility" onClick={_handleFocusVisibilityClick} icon={<Icon name="show" />} />
              <TimelineButton text="Event Visibility" onClick={_handleEventVisibilityClick} icon={<Icon name="show" />} />
              <TimelineButton text={open ? 'Hide Legend' : 'Show Legend'} onClick={toggleLegendDialog} />
            </Stack>
            <Stack direction="row" justifyItems="flex-start" textAlign="center" gap={2}></Stack>
          </Stack>
        </Card>
      </PageLayout>
      <TimelineLegendDialog open={open} onDismiss={toggleLegendDialog} />
    </>
  );
};

export default StudentTimelinePage;

import { StudentGrade, studentGradeLookup } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { UpdateStudentGrade, useUpdateStudentGrade } from '@hoot/hooks/api/user/useUpdateStudentGrade';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select } from '@hoot/ui/components/v2/core/Select';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface Props {
  setOpenDialog: (val: boolean) => void;
  openDialog: boolean;
  studentName: string;
  studentId: string;
  confirmLeave: () => void;
}

const PromptStudentGradeSelectionDialog = (props: Props) => {
  const { setOpenDialog, openDialog, studentName, confirmLeave, studentId } = props;
  const [grade, setGrade] = useState<StudentGrade | null>(null);
  const updateStudentGradeRequest = useUpdateStudentGrade(studentId);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    if (!grade) {
      return;
    }
    const updateStudentGrade: UpdateStudentGrade = {
      grade: grade,
    };
    updateStudentGradeRequest.mutate(updateStudentGrade, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: 'Student Grade Updated!', variant: 'light' }));
        setOpenDialog(false);
      },
      onError: (err) => {
        console.error(err);
        dispatch(createFlashMessage({ variant: 'error', message: 'There was an issue with updating the student grade.' }));
      },
    });
  };

  const closeDialog = () => {
    setOpenDialog(false);
    confirmLeave();
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    setGrade(value as StudentGrade);
  };
  return (
    <BasicAlertDialog
      show={openDialog}
      title={`Confirm ${studentName}'s Grade`}
      content={
        <Stack gap={3}>
          <HootTypography
            isPII={true}
          >{`${`To ensure the accuracy and relevance of the assessment, please confirm ${studentName}'s current grade.`}`}</HootTypography>
          <Select
            label={'Grade'}
            name={'grade'}
            value={grade ?? ''}
            onChange={handleChange}
            required
            sx={{ minWidth: '300px' }}
            helperText={"Note: Grade will be applied to the student's profile and cannot be edited after submitting."}
          >
            <option value="" disabled>
              Select
            </option>
            {Object.values(StudentGrade).map((option) => (
              <option key={`option-${option}`} value={option}>
                {studentGradeLookup[option]}
              </option>
            ))}
          </Select>
        </Stack>
      }
      primaryAction={{
        label: 'Submit',
        onClick: handleSubmit,
        props: { disabled: !grade },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: closeDialog,
      }}
      onDismiss={closeDialog}
    />
  );
};

export default PromptStudentGradeSelectionDialog;

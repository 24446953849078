import { StudentGrade, studentGradeLookup } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSetStudentGrade } from '@hoot/hooks/api/assessment/useSetStudentGrade';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { StudentProfileSnippetResponse } from '@hoot/hooks/api/user/useGetStudentProfileSnippet';
import { error } from '@hoot/redux/reducers/alertSlice';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select } from '@hoot/ui/components/v2/core/Select';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface StudentGradeConfirmationDialogProps {
  show: boolean;
  onConfirm: (assessmentIdToResume: string | undefined, studentProfile: StudentProfileSnippetResponse) => void;
  onDismiss: () => void;
  studentProfile: StudentProfileSnippetResponse;
}

const StudentGradeConfirmationDialog = (props: StudentGradeConfirmationDialogProps) => {
  const { show, onConfirm, onDismiss, studentProfile } = props;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const setGradeRequest = useSetStudentGrade();
  const [studentGrade, setStudentGrade] = useState<StudentGrade | null>(studentProfile.grade);

  // Reset dropdown state when modal is launched.
  useEffect(() => {
    if (show) {
      setStudentGrade(studentProfile.grade);
    }
  }, [show]);

  const onConfirmGrade = () => {
    if (studentGrade) {
      setGradeRequest.mutate(
        { studentProfileId: studentProfile.id, grade: studentGrade },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.GetStudentProfileSnippet);
            onConfirm(undefined, studentProfile);
          },
          onError: (ex) => {
            console.error(ex);
            dispatch(error(`An error occurred while attempting to set the student's grade.`));
          },
        },
      );
    }
  };

  return (
    <BasicAlertDialog
      show={show}
      onDismiss={onDismiss}
      title={`Confirm ${studentProfile.name}'s Grade`}
      content={
        <Stack gap={2}>
          <HootTypography
            isPII={true}
          >{`To ensure the accuracy and relevance of the assessment, please confirm ${studentProfile.name}’s current grade.`}</HootTypography>
          <Select
            label="Grade"
            helperText="Note: Grade will be applied to the student’s profile and cannot be edited after submitting."
            name="studentGrade"
            value={studentGrade}
            onChange={(event) => setStudentGrade(event.target.value as StudentGrade)}
            required
            fullWidth
          >
            <option value="">Select</option>
            {Object.values(StudentGrade).map((option) => (
              <option value={option} key={`grade-opt-${option}`}>
                {studentGradeLookup[option]}
              </option>
            ))}
          </Select>
        </Stack>
      }
      primaryAction={{
        label: 'Submit',
        onClick: onConfirmGrade,
        props: {
          disabled: !studentGrade,
          color: 'success.60',
        },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: onDismiss,
      }}
    />
  );
};

export default StudentGradeConfirmationDialog;
